import { useTheme } from '@mui/material/styles'
import { AppBar, Box, IconButton, Theme, Toolbar } from '@mui/material'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import MenuIcon from '@mui/icons-material/Menu'
import HeaderContent from './HeaderContent'
import { config } from '../theme'
import { useContext } from 'react'
import { LayoutContext } from '..'

export interface HeaderProps {
  unauthorized?: boolean
}

const Header = ({ unauthorized }: HeaderProps) => {
  const { toggleDrawer, drawerOpen } = useContext(LayoutContext)
  const theme = useTheme()

  const iconBackColor = 'grey.100'
  const iconBackColorOpen = 'grey.300'

  const mainHeader = (
    <Toolbar>
      <IconButton
        disableRipple
        aria-label="open drawer"
        onClick={toggleDrawer}
        color="primary"
        sx={{
          color: 'text.primary',
          bgcolor: drawerOpen ? iconBackColorOpen : iconBackColor,
          ml: drawerOpen ? { xs: -1, lg: -2 } : 0,
          mr: 2,
        }}
      >
        {!drawerOpen ? <MenuIcon /> : <MenuOpenIcon />}
      </IconButton>
      <HeaderContent unauthorized={unauthorized} />
    </Toolbar>
  )

  return (
    <AppBar elevation={0} sx={styles(theme, drawerOpen).wrapper}>
      <Box sx={styles(theme, drawerOpen).content}>{mainHeader}</Box>
    </AppBar>
  )
}

export default Header

const styles = (theme: Theme, open: boolean) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    color: 'inherit',
    borderBottom: `1px solid ${theme.palette.divider}`,
    height: config.headerHeight,
    zIndex: theme.zIndex.drawer - 1,
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(2px)',
    boxShadow:
      '0 .125rem 9.375rem rgba(90,97,105,.1),0 .25rem .5rem rgba(90,97,105,.12),0 .9375rem 1.375rem rgba(90,97,105,.1),0 .4375rem 2.1875rem rgba(165,182,201,.1)',
  },
  content: {
    height: config.headerHeight,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open
      ? {
          marginLeft: config.drawerWidth + 'px',
          width: `calc(100% - ${config.drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }
      : { width: '100%' }),
  },
})
