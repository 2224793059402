import { SnackbarKey } from 'notistack'
import { IconButton } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useSnackbar } from 'notistack'

const Snackbar = ({ id }: { id: SnackbarKey }): JSX.Element => {
  const { closeSnackbar } = useSnackbar()

  return (
    <IconButton onClick={() => closeSnackbar(id)}>
      <CloseRoundedIcon />
    </IconButton>
  )
}

export default Snackbar
