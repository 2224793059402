import { SnackbarProvider } from 'notistack'
import { Slide, Box, useTheme, useMediaQuery } from '@mui/material'
import Snackbar from './Snackbar'
import Container, { UnauthorizedContainer } from './Container'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import Header from './Header'
import Drawer from './Drawer'
import CookieConsentBanner from '../components/CookieConsent'
import GlobalProvider from './Providers/GlobalProvider'
import ErrorBoundary from './ErrorBoundary'
import { useRouter } from 'next/router'
import GlobalContext from './Providers/GlobalContext'
import { NextComponentType, NextPageContext } from 'next/types'
import { navItems as allNavItems } from './navItems'
import Custom404 from 'pages/404'
import { useAuth0 } from '@auth0/auth0-react'

interface LayoutContextProps {
  isMobile: boolean
  drawerOpen: boolean
  toggleDrawer: () => void
}

interface MainLayoutProps {
  Component: NextComponentType<NextPageContext, any, any>
  pageProps: any
}

export const LayoutContext = createContext<LayoutContextProps>({
  isMobile: false,
  drawerOpen: false,
  toggleDrawer: () => null,
})

export default function MainLayout(props: MainLayoutProps): JSX.Element {
  const { user } = useAuth0()
  const theme = useTheme()
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'))

  const [open, setOpen] = useState<boolean>(true)

  const handleDrawerToggle = () => {
    setOpen(!open)
  }

  useEffect(() => {
    setOpen(!matchDownLG)
  }, [matchDownLG])

  return (
    <SnackbarProvider
      action={(key) => <Snackbar id={key} />}
      maxSnack={4}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      TransitionComponent={Slide}
      autoHideDuration={6000}
    >
      <GlobalProvider>
        <LayoutContext.Provider
          value={{ isMobile: matchDownLG, drawerOpen: open, toggleDrawer: handleDrawerToggle }}
        >
          <Box sx={{ display: 'flex' }}>
            <Header />
            <Drawer />
            <Container>
              <ErrorBoundary userEmail={user?.email}>
                <RoleAccess {...props} />
              </ErrorBoundary>
            </Container>
          </Box>
          <CookieConsentBanner />
        </LayoutContext.Provider>
      </GlobalProvider>
    </SnackbarProvider>
  )
}

const RoleAccess = (props: MainLayoutProps) => {
  const { Component, pageProps } = props
  const { navItems } = useContext(GlobalContext)
  const router = useRouter()
  const path = router.pathname

  const isPartOfPages = Object.values(allNavItems).some((item) => item.path === path)
  const isValidPath = navItems.map((item) => item.path).includes(path)

  if (isPartOfPages && !isValidPath && path !== '/') {
    return <Custom404 />
  }

  return <Component {...pageProps} />
}

export function UnauthorizedLayout({ children }: { children?: ReactNode }) {
  const theme = useTheme()
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'))

  const [open, setOpen] = useState<boolean>(true)

  const handleDrawerToggle = () => {
    setOpen(!open)
  }

  useEffect(() => {
    setOpen(!matchDownLG)
  }, [matchDownLG])

  return (
    <LayoutContext.Provider
      value={{ isMobile: matchDownLG, drawerOpen: open, toggleDrawer: handleDrawerToggle }}
    >
      <Box sx={{ display: 'flex' }}>
        <Header unauthorized />
        <Drawer unauthorized />
        <UnauthorizedContainer>{children}</UnauthorizedContainer>
      </Box>
      <CookieConsentBanner />
    </LayoutContext.Provider>
  )
}
