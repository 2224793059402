import { Box, List, ListItemButton, ListItemText } from '@mui/material'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import Link from 'next/link'
import { useContext } from 'react'
import GlobalContext from 'src/layout/Providers/GlobalContext'

const DrawerContent = () => {
  const { navItems } = useContext(GlobalContext)
  const router = useRouter()
  const { t } = useTranslation()

  const renderItems = navItems.map((item) => {
    return (
      <Link key={item.path} href={item.path} legacyBehavior>
        <ListItemButton sx={styles} selected={router.pathname.split('/[id]')[0] === item.path}>
          <ListItemText primary={t(`styra.menu.${item.title}`, { defaultValue: item.title })} />
        </ListItemButton>
      </Link>
    )
  })

  return (
    <Box
      sx={{
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <List>{renderItems}</List>
    </Box>
  )
}

export default DrawerContent

const styles = {
  px: 3.5,
  '&:hover': {
    color: 'primary.main',
    backgroundColor: 'primary.10',
  },
  '&.Mui-selected': {
    color: 'primary.main',
    backgroundColor: 'primary.10',
    '&::before': {
      content: '""',
      position: 'absolute',
      height: '100%',
      width: 2,
      backgroundColor: 'primary.main',
      top: 0,
      right: 0,
    },
  },
}
