import { createContext } from 'react'
import { useGetUserByEmail } from '../../operations/queries/getUserByEmail'
import { OrganisationList } from 'src/operations/queries/getOrganisationList'
import { Exact, Get_Organisation_ListQuery, Scalars } from 'gql/orgmgr/graphql'
import { ApolloQueryResult } from '@apollo/client'
import { GetUserByEmailQuery } from 'gql/developer/graphql'
import { NavItemsValueType, RolesType } from '../navItems'

type OrganisationsRefetch = (
  variables?: Partial<
    Exact<{
      [key: string]: never
    }>
  >
) => Promise<ApolloQueryResult<Get_Organisation_ListQuery>>

type UserRefetch = (
  variables?: Partial<
    Exact<{
      email: Scalars['String']['input']
    }>
  >
) => Promise<ApolloQueryResult<GetUserByEmailQuery>>

export interface GlobalContextProps {
  user: ReturnType<typeof useGetUserByEmail>['data']['ta_user'][0]
  currentOrganisation?: OrganisationList[0]
  organisations: OrganisationList
  setCurrentOrganisation: (id: string) => void
  pageError: boolean
  pageLoading: boolean
  refetch: {
    user: UserRefetch
    organisations: OrganisationsRefetch
  }
  navItems: NavItemsValueType[]
  role: RolesType
}

const GlobalContext = createContext<GlobalContextProps>(undefined)

export default GlobalContext
