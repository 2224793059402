import MuiBreadcrumbs from '@mui/material/Breadcrumbs'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { Typography } from '@mui/material'
import { useContext } from 'react'
import GlobalContext from '../Providers/GlobalContext'

const Breadcrumbs = () => {
  const { navItems } = useContext(GlobalContext)
  const router = useRouter()

  const pathNames = router.pathname.split('/').filter((x) => x)
  const asPathsNames = router.asPath.split('/').filter((x) => x)

  const breadcrumbNameMap = navItems.reduce((obj, { path, title }) => {
    obj[path] = title
    return obj
  }, {})

  if (
    !navItems.some((e) => e.path === '/' + pathNames[0]) ||
    !pathNames.length ||
    pathNames[0] === 'management' ||
    pathNames[0] == 'report'
  ) {
    return null
  }

  const formatPathStr = (str: string): string => {
    return str
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  return (
    <div role="presentation">
      <MuiBreadcrumbs aria-label="breadcrumb">
        <Link href="/">
          <Typography>Home</Typography>
        </Link>
        {pathNames.map((value, index) => {
          const last = index === pathNames.length - 1
          const to = `/${asPathsNames.slice(0, index + 1).join('/')}`

          return last ? (
            <Typography color="black" key={to}>
              {breadcrumbNameMap[to] || formatPathStr(value)}
            </Typography>
          ) : (
            <Link href={to} key={to}>
              <Typography>{breadcrumbNameMap[to] || formatPathStr(value)}</Typography>
            </Link>
          )
        })}
      </MuiBreadcrumbs>
    </div>
  )
}

export default Breadcrumbs
