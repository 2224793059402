import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Link, Stack, Typography } from '@mui/material'
import { baseColors } from '../layout/theme'
import CookieConsentBanner from './CookieConsent'

export function Copyright(): JSX.Element {
  return (
    <Typography variant="body1" color="textSecondary" align="center">
      <Link
        fontSize={15}
        underline="hover"
        color="neutral.dark"
        href="https://nemlia.com/"
        target="blank"
      >
        Powered by Nemlia Sp/f
      </Link>
    </Typography>
  )
}

export function Version(): JSX.Element {
  const versionDetails =
    `${process.env.NEXT_PUBLIC_VERSION}/${process.env.NEXT_PUBLIC_BUILD_DATE}` +
    `/${process.env.NEXT_PUBLIC_GITHUB_SHA}/${process.env.NEXT_PUBLIC_GITHUB_RUN_NUMBER}`
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <a href="#" title={versionDetails}>
        .
      </a>
    </Typography>
  )
}

export default function Login(): JSX.Element {
  const { loginWithRedirect, isAuthenticated } = useAuth0()

  if (isAuthenticated) {
    return null
  }
  return (
    <Box height="100vh" position="relative" p={5}>
      <Stack height="90%" justifyContent="center" alignItems="center" spacing={7}>
        <Typography component="h1" variant="h2" fontSize={74}>
          Welcome to <span style={{ color: baseColors.primary, fontWeight: '500' }}>Stýra</span>
        </Typography>
        <Button
          sx={{ borderRadius: 999, fontSize: 20, width: 350, py: 1.5 }}
          size="large"
          variant="contained"
          color="primary"
          onClick={() => loginWithRedirect()}
        >
          Log In
        </Button>
      </Stack>
      <Stack
        width="100%"
        justifyItems="center"
        alignItems="center"
        position="absolute"
        bottom={20}
        left={0}
      >
        <Box>
          <Copyright />
        </Box>
        <Box>
          <Version />
        </Box>
      </Stack>
      <CookieConsentBanner />
    </Box>
  )
}
