import React, { ErrorInfo, ReactNode } from 'react'
import { Error as ErrorMsg } from './Loading'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { exception } from 'src/lib/applicationinsights'

interface ErrorBoundaryProps {
  children: ReactNode
  userEmail?: string
}

interface ErrorBoundaryState {
  hasError: boolean
  organisationId?: string
  userEmail?: string
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    // Access localStorage item and store it in state
    const organisationId = localStorage.getItem('currentOrganisationId')

    // Define a state variable to track whether is an error or not
    this.state = {
      hasError: false,
      organisationId: organisationId || '',
      userEmail: props.userEmail,
    }
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    exception(error, {
      errorInfo,
      msg: 'Stýra error boundary',
      organisationId: this.state.organisationId,
      userEmail: this.state.userEmail,
    })
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // Render fallback UI
      return <ErrorBoundaryMessage onClick={() => this.setState({ hasError: false })} />
    }

    // Return children components in case of no error
    return this.props.children
  }
}

export default ErrorBoundary

interface ErrorBoundaryMessageProps {
  onClick: () => void
}

const ErrorBoundaryMessage = ({ onClick }: ErrorBoundaryMessageProps) => {
  const { t } = useTranslation()

  return (
    <ErrorMsg title={t('styra.message.error_boundary')} message="">
      <Button sx={{ borderRadius: 999, px: 3 }} size="small" variant="outlined" onClick={onClick}>
        {t('styra.action.try_again')}
      </Button>
    </ErrorMsg>
  )
}
