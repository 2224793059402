import { useQuery } from '@apollo/client'
import { orgmgrGql, ResultOf } from '@nemlia/hasura-gql'

const GET_ORGANISATION_LIST = orgmgrGql(/* GraphQL */ `
  query GET_ORGANISATION_LIST {
    ta_organisation(where: { archived: { _eq: false } }, order_by: { name: asc }) {
      id
      name
      supplier_id
      config
      ta_supplier {
        name
      }
      tz: organisation_timezone
    }
  }
`)

export type OrganisationList = ResultOf<typeof GET_ORGANISATION_LIST>['ta_organisation']

export const useGetOrganisationList = () => {
  return useQuery(GET_ORGANISATION_LIST)
}
