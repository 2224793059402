import { useQuery } from '@apollo/client'
import { developerGql } from '@nemlia/hasura-gql'
import { GetVariableType } from '@nemlia/hasura-schema'

const GET_USER_BY_EMAIL = developerGql(/* GraphQL */ `
  query GetUserByEmail($email: String!) {
    ta_user(where: { email: { _eq: $email } }) {
      id
      email
      full_name
      user_role
      user_language
      user_timezone
    }
  }
`)
export const useGetUserByEmail = (input: GetVariableType<typeof GET_USER_BY_EMAIL>) => {
  return useQuery(GET_USER_BY_EMAIL, {
    variables: input,
  })
}
