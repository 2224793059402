import { common } from '@mui/material/colors'
import { alpha, darken } from '@mui/material/styles'

type Color = string | { main: string; dark?: string }
type BaseColors = { [key: string]: Color }

// Function to extract the main color
const getMainColor = (color: Color) => {
  if (typeof color === 'string') {
    return color
  } else if (color.main) {
    return color.main
  }
  return null // Handle invalid input
}

// Generate tint colors for all base colors
export const getBaseColors = (baseColors: BaseColors) =>
  Object.keys(baseColors).reduce((result, key) => {
    const mainColor = getMainColor(baseColors[key])
    if (!mainColor) {
      return result
    }

    result[key] = {
      dark: baseColors[key]['dark'] || darken(mainColor, 0.2),
      main: mainColor,
      contrastText: common.white,
      90: alpha(mainColor, 0.9),
      80: alpha(mainColor, 0.8),
      70: alpha(mainColor, 0.7),
      60: alpha(mainColor, 0.6),
      50: alpha(mainColor, 0.5),
      40: alpha(mainColor, 0.4),
      30: alpha(mainColor, 0.3),
      20: alpha(mainColor, 0.2),
      10: alpha(mainColor, 0.1),
      5: alpha(mainColor, 0.05),
    }
    return result
  }, {})
