import { Stack } from '@mui/material'
import Profile from './Profile'
import OrganisationSelector from './OrganisationSelecter'
import { HeaderProps } from '..'

const HeaderContent = ({ unauthorized }: HeaderProps) => {
  return (
    <Stack
      ml={1}
      width="100%"
      direction="row"
      spacing={3}
      justifyContent="space-between"
      alignItems="center"
    >
      {unauthorized ? null : (
        <>
          <OrganisationSelector />
          <Profile />
        </>
      )}
    </Stack>
  )
}

export default HeaderContent
