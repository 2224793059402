import { Stack, Chip, Box, Typography } from '@mui/material'
import { config } from '../../theme'

const DrawerHeader = () => {
  return (
    <Box
      sx={{
        minHeight: config.headerHeight,
        px: 3.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography color="primary" fontWeight="bold" fontSize={22}>
          STÝRA
        </Typography>

        <Chip
          label={process.env.appVersion}
          size="small"
          sx={{ height: 16, '& .MuiChip-label': { fontSize: '0.625rem', py: 0.25 } }}
        />
      </Stack>
    </Box>
  )
}

export default DrawerHeader

export const LogoIcon = ({
  color = 'inherit',
  width = 80,
}: {
  color?: string
  width?: string | number
}) => {
  return (
    <Stack color={color} width={width}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 174.28 30.67"
        strokeWidth={0}
        width="100%"
      >
        <polygon points="25.2 0 25.2 19.51 5.47 4.33 0 .13 0 30.67 5.47 30.67 5.47 11.29 25.2 26.47 30.67 30.67 30.67 .04 25.2 0" />
        <path d="m43.33,5.47h22.39V0h-22.39c-4.56,0-8.23,3.72-8.23,8.28v14.11c0,4.56,3.68,8.28,8.23,8.28h22.39v-5.47h-22.39c-1.53,0-2.8-1.27-2.8-2.81v-5.79h19.72v-5.47h-19.72v-2.85c0-1.54,1.27-2.81,2.8-2.81" />
        <polygon points="95.32 4.21 85.45 11.79 75.59 4.21 70.12 0 70.12 30.67 75.59 30.67 75.59 11.13 80.98 15.25 85.45 18.71 89.92 15.25 95.32 11.13 95.32 30.67 100.79 30.67 100.79 0 95.32 4.21" />
        <path d="m114.37,25.16c-1.93-.26-3.46-1.8-3.73-3.72V0h-5.47v20.9c0,1.53.35,2.98,1.01,4.29.92,1.93,2.5,3.5,4.42,4.47,1.31.66,2.76,1.01,4.29,1.01h15.56v-5.47l-16.08-.04Z" />
        <rect x="134.84" width="5.47" height="30.67" />
        <polygon points="171.48 25.19 158.64 0 145.8 25.19 142.99 30.67 149.13 30.67 151.93 25.19 158.64 12.05 165.34 25.19 168.15 30.67 174.28 30.67 171.48 25.19" />
      </svg>
    </Stack>
  )
}
