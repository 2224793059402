import i18n, { PostProcessorModule } from 'i18next'
import { initReactI18next } from 'react-i18next'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import Backend from 'i18next-http-backend'
import { apiUrl } from './utils'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

declare module 'i18next' {
  interface i18n {
    failedLoading: boolean
  }
}

const capitalizeFirstLetter = (str: string, capitalize?: boolean) => {
  if (capitalize === true) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  } else if (capitalize === false) {
    return str.charAt(0).toLowerCase() + str.slice(1)
  } else {
    return str
  }
}

const capitalize: PostProcessorModule = {
  type: 'postProcessor',
  name: 'capitalize',
  process: (value, _key, options: { capitalize?: boolean }) => {
    return capitalizeFirstLetter(value, options.capitalize)
  },
}

// Extend i18n with failedLoading prop, to handle request error
i18n.failedLoading = false
i18n.on('failedLoading', () => (i18n.failedLoading = true))

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(intervalPlural) // add plural rules
  .use(capitalize) // add option to capitalize first letter
  .use(Backend) // getting translations from pipit
  .init({
    react: {
      useSuspense: false,
    },
    fallbackLng: `en`,
    load: 'languageOnly',
    backend: {
      loadPath: `${apiUrl()}/rest/v1/translations/get-translations/{{lng}}`,
      request: (
        _options: any,
        url: string,
        payload: AxiosRequestConfig<any> | undefined,
        callback: (arg0: null, arg1: AxiosResponse<any, any> | null) => void
      ) => {
        axios
          .get(url, payload)
          .then((response) => {
            callback(null, response)
          })
          .catch(() => {
            callback(null, null)
          })
      },
    },
    postProcess: ['capitalize'],
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
