import { useMutation } from '@apollo/client'
import { developerGql } from '@nemlia/hasura-gql'
import { GetVariableType } from '@nemlia/hasura-schema'

const UPDATE_USER_LANGUAGE = developerGql(/* GraphQL */ `
  mutation UPDATE_USER_LANGUAGE($id: uuid!, $user_language: String) {
    update_ta_user_by_pk(pk_columns: { id: $id }, _set: { user_language: $user_language }) {
      id
      user_language
    }
  }
`)
export const useUpdateUserLanguage = (input: GetVariableType<typeof UPDATE_USER_LANGUAGE>) => {
  const [mutate, { data, error }] = useMutation(UPDATE_USER_LANGUAGE, {
    variables: input,
  })

  return { mutate, data, error }
}
