import { Drawer } from '@mui/material'
import DrawerContent from './DrawerContent'
import DrawerHeader from './DrawerHeader'
import { config } from '../theme'
import { useContext, useMemo } from 'react'
import { LayoutContext } from '..'

const MainDrawer = ({ unauthorized = false }: { unauthorized?: boolean }) => {
  const { isMobile, toggleDrawer, drawerOpen } = useContext(LayoutContext)
  const drawerContent = useMemo(() => <DrawerContent />, [])
  const drawerHeader = useMemo(() => <DrawerHeader />, [])

  const onClose = () => {
    if (isMobile) {
      toggleDrawer()
    }
  }

  const onClick = (e: any) => {
    if (!isMobile) {
      return
    }

    const target = e?.target

    if (target?.getAttribute('role') === 'button' || target?.nodeName === 'SPAN') {
      toggleDrawer()
    }
  }

  return (
    <Drawer
      sx={{
        width: config.drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: config.drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant={isMobile ? 'temporary' : 'persistent'}
      onClose={onClose}
      anchor="left"
      open={drawerOpen}
      onClick={onClick}
    >
      {drawerOpen && drawerHeader}
      {drawerOpen && !unauthorized && drawerContent}
    </Drawer>
  )
}

export default MainDrawer
