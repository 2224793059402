export type RolesType = 'developer' | 'supplier' | 'organisation_manager'
export type RoleAccessType = {
  [k in RolesType]?: NavItemKeysType[]
}

export type NavItemKeysType = keyof typeof navItems
export type NavItemsType = typeof navItems
export type NavItemsValueType = { title: string; path: string }

// Navigation Items
export const navItems = {
  home: { title: 'Home', path: '/' },
  organisation: { title: 'Organisation', path: '/organisation/current' },
  management: { title: 'Management', path: '/management' },
  report: { title: 'Report', path: '/report' },
  partners: { title: 'Partners', path: '/supplier' },
  organisations: { title: 'Organisations', path: '/organisation' },
  users: { title: 'Users', path: '/user' },
  userGroups: { title: 'User groups', path: '/user-group' },
  citizens: { title: 'Citizens', path: '/citizen' },
  gateway: { title: 'Gateway', path: '/gateway' },
  devices: { title: 'Devices', path: '/device' },
  provisionDevice: { title: 'Provision device', path: '/provision-device' },
  rules: { title: 'Rules', path: '/rules' },
  zones: { title: 'Zones', path: '/zone' },
  notifications: { title: 'Notifications', path: '/notifications' },
  tags: { title: 'Tags', path: '/tag' },
  beacon: { title: 'Beacon', path: '/beacon' },
  sensors: { title: 'Sensors', path: '/sensor' },
  trackers: { title: 'Trackers', path: '/tracker' },
  mockGateway: { title: 'Mock Gateway', path: '/mock-gateway' },
  mockDevices: { title: 'Mock Devices', path: '/mock-devices' },
  mockTracker: { title: 'Mock Tracker', path: '/mock-tracker' },
  support: { title: 'Support', path: '/support' },
  rulesOverview: { title: 'Rules overview', path: '/rules-overview' },
  search: { title: 'Search', path: '/search' },
}

// Define which links are accessible for each role
export const roleAccess: RoleAccessType = {
  supplier: [
    'organisation',
    'management',
    'report',
    'organisations',
    'users',
    'userGroups',
    'citizens',
    'gateway',
    'devices',
    'provisionDevice',
    'rules',
    'zones',
    'notifications',
    'tags',
    'beacon',
    'search',
  ],
  organisation_manager: ['management', 'report', 'provisionDevice'],
}

export const getNavItems = (role?: RolesType): NavItemsValueType[] => {
  if (!role) {
    return []
  }

  if (!roleAccess[role]) {
    return Object.keys(navItems).map((key) => navItems[key])
  }

  return roleAccess[role].map((key) => navItems[key])
}
