import { useEffect, useState } from 'react'
import * as cookie from 'react-cookies'
import { setCookiesEnabled } from '../lib/applicationinsights'
import { Box, Button, Fade, Paper, Stack, Typography } from '@mui/material'
import CookieRoundedIcon from '@mui/icons-material/CookieRounded'

const oneYearFromNow = () => {
  const date = new Date()
  date.setFullYear(date.getFullYear() + 1)

  return date
}

const CookieConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    const consentCookie = cookie.load('cookieConsent')

    if (!consentCookie) {
      setShowBanner(true)
    }
  }, [])

  const handleAccept = () => {
    setShowBanner(false)
    cookie.save('cookieConsent', 'accepted', { expires: oneYearFromNow() })
    setCookiesEnabled(true)
  }

  const handleReject = () => {
    cookie.save('cookieConsent', 'rejected', { expires: oneYearFromNow() })
    cookie.remove('ai_user')
    cookie.remove('ai_session')
    setShowBanner(false)
  }

  return (
    <Fade appear={false} in={showBanner}>
      <Paper
        role="dialog"
        aria-modal="false"
        aria-label="Cookie banner"
        square
        variant="outlined"
        tabIndex={-1}
        sx={{
          zIndex: 9999,
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          m: 1,
          borderWidth: 1,
          borderColor: 'primary.light',
          borderRadius: 2,
          boxShadow:
            'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;',
        }}
      >
        <Stack
          p={3}
          direction={{ sm: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems={{ sm: 'flex-start', md: 'center' }}
          gap={{ sm: 2, md: 3 }}
        >
          <CookieRoundedIcon
            fontSize="inherit"
            sx={{
              fontSize: 64,
              color: 'primary.light',
            }}
          />
          <Box
            sx={{
              flexShrink: 1,
              alignSelf: { xs: 'flex-start', sm: 'center' },
            }}
          >
            <Typography variant="h5" color="primary" mb={1.5}>
              Cookie Preferences
            </Typography>
            <Typography variant="body1" color="neutral.dark">
              We use cookies for essential website functionality and troubleshooting. To keep the
              site working, you need to allow the required cookies. You can choose to accept all
              cookies or just the essential ones.
            </Typography>
          </Box>
          <Stack
            gap={2}
            direction="row"
            sx={{
              flexShrink: 0,
              alignSelf: { sm: 'flex-start', md: 'center' },
            }}
          >
            <Button size="large" onClick={handleAccept} variant="outlined">
              Accept all cookies
            </Button>
            <Button size="large" onClick={handleReject}>
              Only the required cookies
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Fade>
  )
}

export default CookieConsentBanner
