import { CircularProgress, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import { ReactNode } from 'react'

interface LoadingProps {
  message?: string
}

export const Loading = (props: LoadingProps) => {
  const { t } = useTranslation()
  const { message: label = `${t('styra.status.loading', { capitalize: true })}...` } = props

  return (
    <Container>
      <CircularProgress size={50} sx={{ color: 'primary.30' }} />
      <Typography mt={1} maxWidth={300} textAlign="center" color="neutral.dark">
        {label}
      </Typography>
    </Container>
  )
}

interface ErrorProps {
  message?: string
  title?: string
  children?: ReactNode
}

export const Error = (props: ErrorProps) => {
  const { t } = useTranslation()
  const {
    message: label = t('styra.message.error_get', { item: t('styra.item.page_one') }),
    title,
    children,
  } = props

  return (
    <Container>
      <ErrorRoundedIcon sx={{ color: 'neutral.main', fontSize: 64 }} />
      {title ? (
        <Typography textAlign="center" variant="h6" color="neutral.dark">
          {title}
        </Typography>
      ) : null}
      <Typography maxWidth={400} textAlign="center" color="neutral.dark">
        {label}
      </Typography>
      {children ? children : null}
    </Container>
  )
}

const Container = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      width="100%"
      height="80%"
      minHeight={200}
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      {children}
    </Stack>
  )
}
