import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import { keyframes } from '@mui/system'
import { Dispatch, SetStateAction, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OrganisationList } from '../../../operations/queries/getOrganisationList'
import SearchIcon from '@mui/icons-material/Search'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import GlobalContext from 'src/layout/Providers/GlobalContext'

export type OptionType = { value: string; title: string; disabled?: boolean }

const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1

const OrganisationSelector = () => {
  const { role, currentOrganisation, organisations, setCurrentOrganisation, pageLoading } =
    useContext(GlobalContext)
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')

  const groupedData: Record<string, OrganisationList[0]> =
    organisations?.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}) || {}

  const displayedOptions = organisations.filter((option) =>
    containsText(option.name + '' + option.ta_supplier?.name, searchText)
  )

  const noItemSelected = !currentOrganisation?.id
  const noItems = !organisations.length
  const oneItem = organisations?.length === 1
  const error = noItems && !pageLoading
  const noneText = noItems
    ? t('styra.status.no_item', { item: t('styra.item.organisation_other') })
    : t('styra.action.select_item', { item: t('styra.item.organisation_one') })

  return (
    <FormControl variant="standard" error={error}>
      <InputLabel id="organisation-select-label" sx={{ lineHeight: 2.25 }}>
        {t('styra.item.organisation_one', { capitalize: true })}
      </InputLabel>
      <Select
        disabled={oneItem || noItems}
        MenuProps={{
          autoFocus: false,
          PaperProps: {
            sx: {
              '& .MuiMenuItem-root': {
                padding: 1,
                fontSize: 13,
              },
            },
          },
        }}
        size="small"
        disableUnderline={!error}
        fullWidth
        color="primary"
        labelId="organisation-select-label"
        id="organisation-select"
        value={noItems || noItemSelected ? 'none' : currentOrganisation.id}
        onChange={(e) => {
          setCurrentOrganisation(e.target.value)
        }}
        renderValue={(e) => {
          const none = e === 'none'
          return (
            <Stack direction="row" spacing={0.5}>
              {pageLoading ? (
                <Box width={60}>
                  <DotsLoader color="neutral.dark" size={6} />
                </Box>
              ) : (
                <Typography fontSize={15} color={none ? 'neutral.dark' : 'primary.main'}>
                  {none ? noneText : `${groupedData[e]?.name}`}
                </Typography>
              )}
            </Stack>
          )
        }}
        sx={{
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: 'inherit',
          },
        }}
        inputProps={oneItem || pageLoading || noItems ? { IconComponent: () => null } : undefined}
      >
        <ListSubheader sx={{ p: 1, mb: 1 }}>
          <Stack spacing={1}>
            <SearchBar value={searchText} setValue={setSearchText} />
            <Divider absolute />
          </Stack>
        </ListSubheader>
        <MenuItem sx={{ display: 'none' }} value="none" />
        {displayedOptions?.map((o) => {
          return (
            <MenuItem key={o.id} value={o.id}>
              <Typography>{o.name} </Typography>{' '}
              {role !== 'organisation_manager' ? (
                <Typography color="neutral.80" ml={1}>
                  {o.ta_supplier?.name}
                </Typography>
              ) : null}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default OrganisationSelector

const SearchBar = ({
  value,
  setValue,
}: {
  value: string
  setValue: Dispatch<SetStateAction<string>>
}) => {
  const { t } = useTranslation()

  return (
    <TextField
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      fullWidth
      sx={{
        '& .MuiInputBase-root': {
          borderRadius: 999,
          fontSize: 12,
          lineHeight: 0,
          paddingLeft: 1,
          paddingRight: 1,
          paddingTop: 0,
          paddingBottom: 0,
          height: 24,
        },
      }}
      placeholder={t('styra.action.search')}
      type="text"
      variant="outlined"
      size="small"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={(e) => {
        if (e.key !== 'Escape') {
          // Prevents autoselecting item while typing (default Select behaviour)
          e.stopPropagation()
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment sx={{ fontSize: 16, marginRight: 0.8 }} position="start">
            <SearchIcon fontSize="inherit" />
          </InputAdornment>
        ),
        endAdornment: value && (
          <IconButton size="small" aria-label="clear search" onClick={() => setValue('')}>
            <CancelRoundedIcon fontSize="inherit" />
          </IconButton>
        ),
      }}
    />
  )
}

interface DotsLoaderProps {
  color?: string
  duration?: number
  size?: number
}

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0.8);
    opacity: 0.6;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
`
const DotsLoader = (props: DotsLoaderProps) => {
  const { duration = 1.5, color = 'primary.main', size = 8 } = props
  const dotBase: SxProps<Theme> = {
    width: size,
    height: size,
    bgcolor: color,
    borderRadius: 999,
  }

  return (
    <Box>
      <Stack
        direction="row"
        spacing={0.5}
        display="inline-flex"
        sx={{
          '& > div': {
            animation: `${bounce} ${duration}s infinite ease-in-out both`,
          },
          '& > div:nth-of-type(1)': {
            animationDelay: `-${duration * 0.32}s`,
          },
          '& > div:nth-of-type(2)': {
            animationDelay: `-${duration * 0.16}s`,
          },
        }}
      >
        <Box sx={dotBase} />
        <Box sx={dotBase} />
        <Box sx={dotBase} />
      </Stack>
    </Box>
  )
}
