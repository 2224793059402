import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Error } from 'src/layout/Loading'

export default function Custom404() {
  const { t } = useTranslation()

  return (
    <Error message="">
      <Typography variant="h5" color="neutral.dark">
        404 - {t('styra.message.404')}
      </Typography>
    </Error>
  )
}
